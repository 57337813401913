<template>
  <ion-page>
    <Header name="FAQ" />
    <ion-content :fullscreen="true">
      <div id="container">

        <ion-loading
          :is-open="isLoading"
          message="Lade Daten.."
          @onDidDismiss="setLoading(false)"
          :duration="3000"
        >
        </ion-loading>

        <ion-grid>
          <ion-row v-if="hasInfobox">
            <ion-col>
              <div class="infobox">
                <h3 class="font-face--merrisans light">{{ infobox.title }}</h3>
                <div class="font-face--merrisans light" v-html="getHTML(infobox.body)"></div>
              </div>
            </ion-col>
          </ion-row>

          <div v-if="hasFaqs">
            <ion-row v-for="(faq, index) in faqs" :key="index">
              <ion-col>
                <details class="faq-item" :id="`t3id_${faq.typo3_id}`" @click="closeOthers">
                  <summary class="font-face--merrisans" @click="closeThis(faq.typo3_id)">{{ faq.question }}</summary>
                  <div class="details--inner font-face--merrisans light" role="textbox" v-html="faq.answer"></div>
                </details>
              </ion-col>
            </ion-row>
          </div>

          <ion-row class="no-data" v-if="showNoFaqs">
            <ion-col>
              <div class="card">
                <h3>Keine FAQs</h3>
              </div>
            </ion-col>
          </ion-row>

        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow,
  IonLoading 
} from '@ionic/vue';
import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'Faq',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading
  },
  data () {
    return {
      faqs: [],
      infobox: {
        title: '',
        body: ''
      },
      isLoading: false,
      firstLoadDone: false
    }
  },
  computed: {
    hasInfobox (): boolean {
      return (this as any).infobox?.title?.length
    },
    hasFaqs (): boolean {
      return !(this as any).isLoading && (this as any).faqs.length
    },
    showNoFaqs (): boolean {
      return !(this as any).isLoading && (this as any).firstLoadDone && !(this as any).faqs.length
    }
  },
  methods: {
    ...filterHelperMethods,
    closeOthers (event: Event) {
      console.log(event)
      const $faqItems: NodeList = document.querySelectorAll('details')
      if ($faqItems && $faqItems.length) {
        for (let i in $faqItems) {
          if ($faqItems[i].nodeName && $faqItems[i].nodeName === 'DETAILS') {
            ($faqItems[i] as HTMLDetailsElement).removeAttribute('open')
          }
        }
      }
    },
    closeThis (id: any) {
      console.log(id)
      const $faqItem: any = document.querySelector(`#t3id_${id}`)
      console.log($faqItem)
      if ($faqItem && $faqItem.hasAttribute('open')) {
          console.log('is open')
          setTimeout(() => {
            $faqItem.removeAttribute('open')
          }, 200)
      }
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    reset () {
      (this as any).isLoading = false;
      (this as any).infobox = {};
      (this as any).faqs = [];
    },
    doRefresh () {
      if (!(this as any).isLoading) {
        (this as any).reset();
        (this as any).loadData();
      }
    },
    async loadData () {
      console.log('start load');
      const vm = (this as any);

      vm.setLoading(true);

      try {
        const info = await api.getFaqInfobox();
        console.log(info.data);

        if (info.data?.title.length) {
          vm.infobox = info.data;
          setTimeout(() => {
            const $anchors: NodeList | null = document.querySelectorAll('.anchors a');
            if ($anchors && $anchors.length) {
              for (let i in $anchors) {
                if ($anchors[i].nodeName && $anchors[i].nodeName === 'A') {
                  console.dir($anchors[i]);
                  $anchors[i].addEventListener('click', (e) => {
                    e.preventDefault();
                    const id: string | null = (e.target as HTMLElement).getAttribute('data-typo3id')
                    if (id) {
                      vm.goToAnchor(id)
                    }
                  })
                }
              }
            }
          }, 500);
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      try {
        const faqs = await api.getFaqQuestions();
        console.log(faqs.data);

        if (faqs.data.length) {
          for (let i in faqs.data) {
            vm.faqs.push(faqs.data[i]);        
          }
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      console.log(vm.faqs);
      vm.firstLoadDone = true;
      setTimeout(function() {
        vm.setLoading(false);
      }, 500);
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    },
    goToAnchor (id: string) {
      const $faqItem: HTMLDetailsElement | null = document.querySelector(`#t3id_${id}`)
      if ($faqItem) {
        $faqItem.click() // close others
        $faqItem.scrollIntoView({ behavior: 'smooth' })
        setTimeout(() => {
          $faqItem.setAttribute('open', 'open')
        }, 500)
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave faq');
    (this as any).reset();
  },
  ionViewDidEnter () {
    console.log('[ionView] entered faq');
    (this as any).doRefresh();
  },
  mounted () {
    const vm = (this as any);
    vm.doRefresh();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive) {
        // vm.scrollTop();
        vm.doRefresh();
      }
    });
  }
}
</script>

<style scoped>
.faq-item {
  scroll-margin-top: 70px;
}
</style>

<style lang="less">
details > summary {
  list-style: none;
}
details summary::marker {
  display:none;
}

.infobox {
  background: var(--background-lightblue);
  color: #fff;
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 15px;

  .infobox {
    padding: 0;
    margin-bottom: 0;
  }

  h3 {
    color: #fff;
    margin-top: 0px;
    font-size: 22px;
    line-height: 33px;
  }

  p {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 5px;
  }

  a {
    color: #fff;
  }
}

details {
  summary {
    position: relative;
    cursor: pointer;
    outline: none;
    font-size: 15px;
    line-height: 28px;
    vertical-align: bottom;
    background: #fff;
    padding: 15px;
    padding-right: 40px;
    color: var(--text-color-blue);
    border-radius: 3px;

    &::-webkit-details-marker {
      display: none;
    }

    &:after {
      content: " ";
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      -webkit-mask: url('~@/assets/img/icons/arrow-next-black.svg');
      mask: url('~@/assets/img/icons/arrow-next-black.svg');
      -webkit-mask-size: cover;
      mask-size: cover;
      background: var(--text-color-blue);
      width: 16px;
      height: 12px;
      background-repeat: no-repeat;
      transition: all .2s ease-in-out;
    }
  }

  &[open] {
    summary {
      color: var(--text-color-green);
      border-radius: 3px 3px 0 0;
    
      &:after {
        transform: translateY(-50%) rotate(-90deg);
        background: var(--text-color-green);
      }
    }
  }

  .details--inner {
    background: #fff;
    color: var(--text-color-grey);
    padding: 5px 15px 20px;
    border-radius: 0 0 3px 3px;
    font-size: 15px;
    line-height: 28px;

    p {
      margin: 0;
      font-size: 15px;
      line-height: 28px;
    } 
  }
}

@media (prefers-color-scheme: dark) {
  details {
    summary {
      background: var(--background-darkmode-grey);
    }
    .details--inner {
      background: var(--background-darkmode-grey);
      color: #fff;
    }
  }
}
body.dark {
  details {
    summary {
      background: var(--background-darkmode-grey);
    }
    .details--inner {
      background: var(--background-darkmode-grey);
      color: #fff;
    }
  }
}
</style>