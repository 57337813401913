
import { 
  IonPage, 
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow,
  IonLoading 
} from '@ionic/vue';
import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'Faq',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading
  },
  data () {
    return {
      faqs: [],
      infobox: {
        title: '',
        body: ''
      },
      isLoading: false,
      firstLoadDone: false
    }
  },
  computed: {
    hasInfobox (): boolean {
      return (this as any).infobox?.title?.length
    },
    hasFaqs (): boolean {
      return !(this as any).isLoading && (this as any).faqs.length
    },
    showNoFaqs (): boolean {
      return !(this as any).isLoading && (this as any).firstLoadDone && !(this as any).faqs.length
    }
  },
  methods: {
    ...filterHelperMethods,
    closeOthers (event: Event) {
      console.log(event)
      const $faqItems: NodeList = document.querySelectorAll('details')
      if ($faqItems && $faqItems.length) {
        for (let i in $faqItems) {
          if ($faqItems[i].nodeName && $faqItems[i].nodeName === 'DETAILS') {
            ($faqItems[i] as HTMLDetailsElement).removeAttribute('open')
          }
        }
      }
    },
    closeThis (id: any) {
      console.log(id)
      const $faqItem: any = document.querySelector(`#t3id_${id}`)
      console.log($faqItem)
      if ($faqItem && $faqItem.hasAttribute('open')) {
          console.log('is open')
          setTimeout(() => {
            $faqItem.removeAttribute('open')
          }, 200)
      }
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    reset () {
      (this as any).isLoading = false;
      (this as any).infobox = {};
      (this as any).faqs = [];
    },
    doRefresh () {
      if (!(this as any).isLoading) {
        (this as any).reset();
        (this as any).loadData();
      }
    },
    async loadData () {
      console.log('start load');
      const vm = (this as any);

      vm.setLoading(true);

      try {
        const info = await api.getFaqInfobox();
        console.log(info.data);

        if (info.data?.title.length) {
          vm.infobox = info.data;
          setTimeout(() => {
            const $anchors: NodeList | null = document.querySelectorAll('.anchors a');
            if ($anchors && $anchors.length) {
              for (let i in $anchors) {
                if ($anchors[i].nodeName && $anchors[i].nodeName === 'A') {
                  console.dir($anchors[i]);
                  $anchors[i].addEventListener('click', (e) => {
                    e.preventDefault();
                    const id: string | null = (e.target as HTMLElement).getAttribute('data-typo3id')
                    if (id) {
                      vm.goToAnchor(id)
                    }
                  })
                }
              }
            }
          }, 500);
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      try {
        const faqs = await api.getFaqQuestions();
        console.log(faqs.data);

        if (faqs.data.length) {
          for (let i in faqs.data) {
            vm.faqs.push(faqs.data[i]);        
          }
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      console.log(vm.faqs);
      vm.firstLoadDone = true;
      setTimeout(function() {
        vm.setLoading(false);
      }, 500);
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    },
    goToAnchor (id: string) {
      const $faqItem: HTMLDetailsElement | null = document.querySelector(`#t3id_${id}`)
      if ($faqItem) {
        $faqItem.click() // close others
        $faqItem.scrollIntoView({ behavior: 'smooth' })
        setTimeout(() => {
          $faqItem.setAttribute('open', 'open')
        }, 500)
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave faq');
    (this as any).reset();
  },
  ionViewDidEnter () {
    console.log('[ionView] entered faq');
    (this as any).doRefresh();
  },
  mounted () {
    const vm = (this as any);
    vm.doRefresh();

    vm.$bus.on('appStateChanged', (isActive: boolean) => {
      console.log('caught global app state change event', isActive);
      if (isActive) {
        // vm.scrollTop();
        vm.doRefresh();
      }
    });
  }
}
